import { useEffect } from "react";

const OpenWidget = () => {
    useEffect(() => {
        const observer = new MutationObserver((mutationsList, obs) => {
            for (let mutation of mutationsList) {
                if (mutation.type === "childList") {
                    const aichatbotWidgetButton = document.getElementById("aichatbot-widget-button");

                    if (aichatbotWidgetButton) {
                        aichatbotWidgetButton.click();
                        obs.disconnect();
                        break;
                    }
                }
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, []);

    return null;
};

export default OpenWidget;