import { useEffect, useState } from "react";

const Refresh = () => {
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    const pii = "You have agreed not to enter any personal identifying information";
    let chatContainers = [];

    // Function to check for PII in chat containers
    const piiCheck = () => {
      let piiFound = false;
      chatContainers.forEach((chatContainer) => {
        if (chatContainer?.innerText.includes(pii)) {
          // console.log("PII detected in:", chatContainer);
          piiFound = true;
        }
      });
      if (piiFound) {
        setShouldRefresh(true);
      }
    };

    // MutationObserver callback to track added/updated containers
    const mutationCallback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "childList") {
          const addedNodes = Array.from(mutation.addedNodes);
          addedNodes.forEach((node) => {
            if (node.classList?.contains("widget-markdown")) {
              // console.log("New chat container detected:", node);
              chatContainers.push(node); // Add to the list of monitored containers
            }
          });
        }
      });
    };

    // Attach a MutationObserver to the body
    const bodyObserver = new MutationObserver(mutationCallback);
    bodyObserver.observe(document.body, { childList: true, subtree: true });

    // Run PII check every 2 seconds
    const interval = setInterval(() => {
      chatContainers = document.body.querySelectorAll(".widget-markdown"); // Update chatContainers dynamically
      // console.log("chat container dawg");
      piiCheck();
    }, 2000);

    // Cleanup on unmount
    return () => {
      bodyObserver.disconnect();
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      const timer = setTimeout(() => {
        const refreshIcon = document.getElementById("aichatbot-widget-refresh-icon");
        if (refreshIcon) {
          refreshIcon.click();
          // console.log("Refresh button clicked!");
        }
        setShouldRefresh(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [shouldRefresh]);

  return null;
};

export default Refresh;