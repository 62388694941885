import { createGlobalStyle } from 'styled-components';
import BackgroundImg from '../img/moonsbackground.svg';

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-weight: 400;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100vh;
        
        @media (min-width: 600px) {
            background-color: #EBEBEB;
        }
    }

    #root {
        @media (min-width: 600px) {
            background: top center / cover no-repeat url(${BackgroundImg});
            overflow: hidden;
        }
    }

    h1,h2,h3,h4,h5,h6,p,ul,li,ol,a {
        color: #2e3192;
        margin: 0;
    }

    h1 {
        font-size: 2.4em;
        font-weight: 600;
    }

    h2 {
        font-size: 2em;
    }

    h3 {
        font-size: 1.75em;
    }

    p, a, li, ol, ul {
        font-family; "Open Sans", sans-serif;
        font-size: 1em;
        color: #000000;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
`;
export default GlobalStyle;