import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { Helmet, HelmetProvider } from "react-helmet-async";

// containers
import Front from './Front';
import ChatBot from './ChatBot';

// components
import Header from '../components/Header';
import GlobalStyle from '../utilities/GlobalStyles';

const PrivateRoute = ({ children }) => {
  const getInner = Cookies.get('getInner');
  return getInner ? children : <Navigate to="/" />;
};

const App = () => {
  return (
    <>
      <GlobalStyle />
      <HelmetProvider>
        <Helmet >
          <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap" rel="stylesheet" type="text/css" />
        </Helmet>
        <Header />
      </HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={ <Front /> } />
          <Route 
            path="/chat" 
            element={
              <PrivateRoute>
                  <ChatBot />
              </PrivateRoute>
            } 
            />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;